import { graphql } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import OfficeListing from "../components/OfficeListing/OfficeListing"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import { formTracking } from "../components/Common/utils"

const ContactTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const officeData = data?.allStrapiOffice?.edges
  const [shareIcons, setShareIcons] = useState(false)

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }


  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  var ldJson = {
    "@context": "http://www.schema.org",
"@type": "RealEstateAgent",
name: "Real Estate in Dubai",
url: pageurl,
logo: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png",
image: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/haus_homepage_contact_headoffice_e092721ac2_9585806d12.png",
"description": PageData?.seo?.metaTitle,
"address": {
"@type": "PostalAddress",
"addressCountry": "Dubai"
},
"geo": {
"@type": "GeoCoordinates",
"latitude": "25.1262428",
"longitude": "55.1428478"
},
// hasMap : "[MapURL!]",
// sameAs : "[Map URL!]",
openingHours: "[Mon - Fri:[9.00AM - 6.00PM], Sat: [9.00AM - 6.00PM], Sun: Closed",
email: "info@hausandhaus.com",
telephone: "+971 4 302 5800",
contactPoint: 	{
"@type": "ContactPoint",
contactType: "Sales",
telephone: "+971 4 302 5800"
},
aggregateRating: {
"@type": "AggregateRating",
ratingValue: "4.7",
reviewCount: "700+"
}
}

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>
      <div className="layout-padding-top"></div>
      
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={PageData?.choose_menu}
            pagename={PageData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>
      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" && (
              <ContentComponent data={item} />
            )}

            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" && (
              <LandingStaticCard data={item} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "offices" && (
                <OfficeListing
                  officeData={officeData}
                  strapi_id={PageData?.strapi_id}
                />
              )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

      />
  )
}

export default ContactTemplate

export const query = graphql`
  query ($page_id: String) {
    allStrapiOffice(filter: { publish: { eq: true } },sort: { fields: rank, order: ASC }) {
      edges {
        node {
          title
          phone
          email
          tile_image {
            url
          }
          imagetransforms {
            image_Transforms
          }
        }
      }
    }
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          title
          description {
            data {
              description
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          select_module
          __typename
        }


        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              link_type
              custom_link
              title
            }
          }
        }
      }
    }
  }
`
